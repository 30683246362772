<template>
  <div v-if="relatedStores?.length" class="sela-container">
    <SelaSwiper
      :items="relatedStores"
      class="my-6"
      :title="t('related_stores')"
      auto-slides
      swiper-navigation
    >
      <template #slide="{ item }">
        <div class="sm:px-1">
          <StoresCard
            :enable-navigate="!isDomain"
            class="transition-transform duration-300 transform hover:scale-105 mt-3"
            :store="(item as Store)"
            @click="goToRoute(item)"
          />
        </div>
      </template>
    </SelaSwiper>
  </div>
</template>

<script setup lang="ts">
import type { Store } from '~~/composables/useMenuModel'

defineProps<{
  relatedStores: Store[] | undefined
}>()

const isDomain = useIsDomain()
const { t } = useI18n()

function goToRoute(item: Store) {
  if (isDomain) {
    const url = item.domain || `${usePlatform()}/stores/${item.id}`
    navigateTo(`http://${url}`, {
      open: {
        target: '_blank'
      }
    })
  }
}
</script>
