<template>
  <SelaSwiper
    :items="sliderItems"
    :swiper-navigation="sliderItems.length > 1"
    class="aspect-[3.06]"
  >
    <template #slide="{ item }">
      <div class="relative h-full">
        <NuxtImg
          provider="cacheIpx"
          preset="modified"
          :src="item.src"
          :alt="item.alt"
          :quality="100"
          width="502"
          height="164"
          sizes="sm:1000px lg:1500px"
          class="w-full h-full object-cover"
        />
        <div
          v-if="item.offer"
          class="flex items-center absolute bottom-2 left-1/2 -translate-x-1/2 px-2 pt-1 bg-bg_color shadow text-sm sm:text-base rounded-lg w-max"
        >
          <div class="text-center mx-2 sm:mx-7 flex-shrink-0">
            <div class="text-text_color line-through">
              {{ item.offer.originalPrice }}
            </div>
            <div class="text-nav_color font-bold">
              {{
                t('discount_percent', {
                  value: parseInt(item.offer.discountRate)
                })
              }}
            </div>
          </div>

          <SelaSubmitButton
            v-if="store?.cart_visible"
            :title="t('order_now')"
            color="bg-button_color mb-1"
            text-color="text-text_alt_color"
            type="button"
            padding="whitespace-nowrap px-2 pt-2 pb-1"
            :loading="loading"
            @click="addOfferLocal(item.offer)"
          />
          <SelaSubmitButton
            v-else
            :title="t('contact_via_whatsApp')"
            color="bg-button_color mb-1"
            text-color="text-text_alt_color"
            type="button"
            padding="whitespace-nowrap px-2 pt-2 pb-1"
            :loading="loading"
            @click="useContactViaWhatsAppForMaterials(undefined, item.offer)"
          />
        </div>
      </div>
    </template>
  </SelaSwiper>
</template>

<script setup lang="ts">
import { useToast } from 'vue-toastification'
import type { GroupOffer, Store } from '~/composables/useMenuModel'
const props = defineProps<{
  store: Store
}>()

/* const { data: offersGroup } = await useApiFetch('/MaterialGroupOffer', {
  query: { 'store_ids[]': [props.store?.id], limit: 1000 },

  transform: (data: SuccessResponse<GroupOffer[]>) => {
    const { groupOfferModel } = useMenuModel()
    return data.data.filter((el) => el.is_active === 1).map(groupOfferModel)
  }
}) */

const loading = ref(false)
const { t } = useI18n()

const sliderItems = computed(() => {
  const items: { src: string; alt: string; offer?: GroupOffer }[] = []

  if (props.store) {
    if (props.store.images?.length) {
      items.push(
        ...props.store.images!.map((el) => ({
          src: el.image,
          alt: props.store.name
        }))
      )
    } else {
      items.push({
        src: props.store.image,
        alt: props.store.name
      })
    }
  }

  /*  if (offersGroup?.value) {
    items.push(
      ...offersGroup.value.map((el) => ({
        src: el.image,
        alt: el.offer_number,
        offer: el
      }))
    )
  } */

  return items
})

async function addOfferLocal(offer: GroupOffer) {
  const { addOffer } = useCartStore()
  const toast = useToast()

  if (!loading.value) {
    loading.value = true
    if (await addOffer(offer.id, offer.store_id)) {
      toast.success(t('add_done', { to: t('to'), name: t('cart') }), {
        timeout: 1000
      })
    } else {
      toast.error(t('add_failed'), { timeout: 1000 })
    }
    loading.value = false
  }
}
</script>
