<template>
  <div v-if="store" class="sela-container">
    <div
      class="items-center relative py-2 ltr:pl-12 rtl:pr-12 mt-5 bg-button_color text-text_alt_color rounded-lg"
      style="min-height: 64px"
    >
      <StoresDialogeBranch
        v-if="!useIsDomain()"
        :store="store"
        :selected-branch="selectedBranch"
        class="absolute -top-0 left-5"
      />
      <div
        class="absolute -top-3 ltr:left-2 rtl:right-2 overflow-hidden flex flex-col items-center justify-center"
      >
        <div class="meta-icon" :class="{ active: active == 1 }">
          <button
            class="m-0 block w-5 h-5"
            style="line-height: 0.8"
            @click="active = 1"
          >
            <v-icon icon="mdi mdi-menu" :size="16" />
          </button>
        </div>
        <div></div>
        <div class="meta-icon" :class="{ active: active == 2 }">
          <button
            class="m-0 block w-5 h-5"
            style="line-height: 0.8"
            @click="active = 2"
          >
            <v-icon icon="mdi mdi-share-variant" :size="16" />
          </button>
        </div>

        <div class="meta-icon" :class="{ active: active == 3 }">
          <button
            class="m-0 block w-5 h-5"
            style="line-height: 0.8"
            @click="active = 3"
          >
            <v-icon
              v-if="settings.show_city_name == '1'"
              icon="fa: fa-solid fa-location-dot"
              :size="16"
            />
            <div v-else class="rtl:pl-1 ltr:pr-1">
              <v-icon icon="fa: fa-solid fa-truck" :size="14" />
            </div>
          </button>
        </div>
      </div>

      <div class="mt-2">
        <div v-if="active == 1">
          <div class="flex items-center">
            <div class="font-medium text-lg">{{ store.name }}</div>
            <div class="hidden sm:block mx-1">-</div>
            <div class="hidden sm:block font-medium">
              {{ store.type?.name }}
            </div>
          </div>
          <div v-if="store.about" class="my-1 prose text-text_alt_color">
            <div v-html="store.about"></div>
          </div>
        </div>

        <div v-if="active == 2" class="h-full flex items-center">
          <div v-if="store.store_email" class="mx-2 sm:mx-5">
            <a :href="`mailto:${store.store_email}`" target="_blank">
              <v-icon icon="mdi-email" :size="16" class="block mb-1 mx-auto" />
              <div class="icon-style-text" dir="ltr">
                {{ store.store_email }}
              </div>
            </a>
          </div>
          <div
            v-if="parseFloat(informationStore.phone ?? '')"
            class="mx-2 sm:mx-5"
          >
            <a :href="`tel:${informationStore.phone}`" target="_blank">
              <v-icon icon="mdi-phone" :size="16" class="block mb-1 mx-auto" />
              <div class="icon-style-text" dir="ltr">
                {{ informationStore.phone }}
              </div>
            </a>
          </div>
          <div v-if="informationStore.whatsappNumber" class="mx-2 sm:mx-5">
            <a
              :href="`https://api.whatsapp.com/send?phone=${informationStore.whatsappNumber}`"
              target="_blank"
            >
              <v-icon
                icon="mdi-whatsapp"
                :size="16"
                class="block mb-1 mx-auto"
              />
              <div class="icon-style-text" dir="ltr">
                {{ informationStore.whatsappNumber }}
              </div>
            </a>
          </div>
          <div v-if="store.facebook" class="mx-2 sm:mx-5">
            <a :href="store.facebook" target="_blank">
              <v-icon
                icon="mdi-facebook"
                :size="16"
                class="block mb-1 mx-auto"
              />
              <div class="icon-style-text">{{ t('facebook') }}</div>
            </a>
          </div>
          <div v-if="store.instagram" class="mx-2 sm:mx-5">
            <a :href="store.instagram" target="_blank">
              <v-icon
                icon="mdi-instagram"
                :size="16"
                class="block mb-1 mx-auto"
              />
              <div class="icon-style-text">{{ t('instagram') }}</div>
            </a>
          </div>
          <div v-if="store.whats_app_channel" class="mx-2 sm:mx-5">
            <a :href="store.whats_app_channel" target="_blank">
              <v-icon :size="16" class="block mb-1 mx-auto">
                <IconsWhatsappChannel />
              </v-icon>
              <div class="icon-style-text">{{ t('whatsapp_channel') }}</div>
            </a>
          </div>
        </div>

        <div v-else-if="active == 3">
          <a
            v-if="
              informationStore.detailedAddress && settings.show_city_name == '1'
            "
            :href="`https://www.google.com/maps/search/?api=1&query=${informationStore?.location?.lat},${informationStore?.location?.lng}`"
            target="_blank"
          >
            <div class="whitespace-nowrap overflow-hidden text-ellipsis">
              {{ informationStore.detailedAddress }}
            </div>
          </a>

          <div class="flex items-center mt-1">
            <img
              v-if="informationStore.supportDelivery"
              src="/images/delivery.png"
              class="w-10 h-8"
            />
            <IconsShippingCarNoDelivery v-else class="w-8 h-6" />
            <div class="mx-2">
              <div v-if="informationStore.supportDelivery">
                {{ t('support_shipping') }}
              </div>
              <div v-else>{{ t('dose_not_support_shipping') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Store } from '~/composables/useMenuModel'

const props = defineProps<{
  store: Store | null
}>()
const { settings } = useSettings()
const active = ref(1)
const { t } = useI18n()

const selectedBranch = computed(() => {
  return props.store
    ? useCurrentBranch(undefined, props.store?.branches)
    : undefined
})
const informationStore = computed(() => {
  return {
    detailedAddress:
      selectedBranch.value?.address || props.store?.detailed_address,
    location: selectedBranch.value?.location || props.store?.location,
    phone: selectedBranch.value?.phone || props.store?.owner_phone,
    whatsappNumber:
      selectedBranch.value?.phone || props.store?.whats_app_number,
    supportDelivery:
      selectedBranch.value?.support_delivery || props.store?.support_delivery
  }
})
</script>

<style scoped>
.meta-icon {
  @apply my-0.5 bg-bg_color rounded-full border border-button_color p-[3px] transition-all duration-200 text-text_color;
}
.meta-icon.active {
  @apply bg-text_alt_color text-nav_color;
}
.icon-style-text {
  @apply hidden sm:block sm:text-sm md:text-base;
}
</style>
